import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeUk from '@angular/common/locales/uk';
import {
  isDevMode,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../../environment';
import { AuthModule } from '../../modules/auth/auth.module';
import {
  AppAbility,
  Locale,
} from '../../modules/core';
import { CoreModule } from '../../modules/core/core.module';
import { PublicLayoutComponent } from '../../modules/layouts';
import {
  ErrorsState,
  LazyTranslateLoader,
} from '../../modules/shared';
import { SharedModule } from '../../modules/shared/shared.module';
import { UserModule } from '../../modules/user/user.module';

registerLocaleData(localeEn, Locale.en);
registerLocaleData(localeUk, Locale.uk);

@NgModule({
  declarations: [
    PublicLayoutComponent,
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    SharedModule.forRoot(),
    NgxsModule.forRoot([ErrorsState], {
      developmentMode: !environment.production,
    }),
    AbilityModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    AuthModule.forRoot(),
    ReactiveFormsModule,
    UserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: LazyTranslateLoader },
    })],
  providers: [
    { provide: AppAbility, useValue: AppAbility() },
    { provide: PureAbility, useExisting: AppAbility },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
}
