import { Component } from '@angular/core';

import { UserService } from '../../core';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['../layout.scss'],
})
export class PublicLayoutComponent {
  constructor(
    private userService: UserService
  ) {
    this.userService.openHomePage();
  }
}
