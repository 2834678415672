import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  ActionLogsService,
  AdminSettingsService,
  ApiService,
  AppService,
  CheckNetworkService,
  CheckVersionService,
  CommentsService,
  DashboardService,
  HelperPrepareParamsService,
  JwtService,
  LocaleService,
  MediaService,
  NotificationsService,
  OnboardingService,
  ProjectsService,
  SocialService,
  TeamMemberService,
  TeamsService,
  TicketsService,
  ToastsService,
  UserService,
  UsersService,
  ValidationService,
} from './services';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule, private translateService: TranslateService) {
    if (core) {
      throw new Error(this.translateService.instant('validations.shouldImportCoreModule'));
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AdminSettingsService,
        ApiService,
        AppService,
        JwtService,
        ToastsService,
        UserService,
        ValidationService,
        SocialService,
        UsersService,
        MediaService,
        CheckNetworkService,
        CheckVersionService,
        LocaleService,
        HelperPrepareParamsService,
        DashboardService,
        ActionLogsService,
        NotificationsService,
        TeamsService,
        TeamMemberService,
        TicketsService,
        OnboardingService,
        ProjectsService,
        CommentsService,
      ],
    };
  }
}
